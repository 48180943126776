<template>
  <nav id="nav" :style="{ display: displayMenu }" :key="navKey">
    <div class="container">
      <div class="premenu row">
        <div class="c-menu" @click.prevent="ocMenu()"></div>
        <app-user class="user_show"></app-user>
      </div>
      <div class="parmenu">
        <ul style="display: inline">
          <li
            v-for="(item, index) in applicationMenu"
            :key="index"
            class="parent-menu-item menu-item col"
            @mouseenter="desktop ? toggleMenu(index, item) : null"
            @mouseleave="desktop ? toggleMenu(index, item) : null"
          >
            <a :class="{ active: item.toggle }" :href="item.href">
              {{ getMenuItemText(item.mnu_key, item.name) }}
            </a>
            <div class="toggle" :class="{ active: item.toggle }" @click="toggleMenu(index, item)"></div>
            <ul class="submenu" v-show="item.ChildItemList && item.toggle" :key="item.toggle">
              <li
                v-for="(subitem, subIndex) in item.ChildItemList"
                :key="subIndex"
                @click="toggleSubmenu(index, subIndex)"
              >
                <a v-if="subitem.title" :href="subitem.href" :class="{ active_submenu: subitem.toggle }">
                  <img
                    class="icon icon-down"
                    src="../assets/svgs/menu-arrow-down.svg"
                    v-show="subitem.toggle === false"
                  />
                  <img
                    class="icon icon-up"
                    src="../assets/svgs/menu-arrow-up.svg"
                    v-show="subitem.toggle === true"
                    :key="subitem.toggle"
                  />
                  {{ getMenuItemText(subitem.mnu_key, subitem.title) }}
                </a>
                <ul
                  :class="{ 'inner-submenu': true, active: subitem.toggle }"
                  v-show="subitem.toggle"
                  :key="subitem.toggle"
                >
                  <li v-for="(listItem, xl) in subitem.MenuItemList" :key="xl">
                    <router-link
                      v-if="listItem.href.indexOf('http') === -1"
                      :to="{ path: listItem.href }"
                      @click.native="ocMenu()"
                    >
                      {{ getMenuItemText(listItem.mnu_key, listItem.name) }}
                    </router-link>
                    <a
                      v-if="listItem.href.indexOf('http') > -1"
                      :href="listItem.href.replace('/menu', '')"
                      target="_blank"
                    >
                      {{ getMenuItemText(listItem.mnu_key, listItem.name) }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="parent-menu-item menu-item col">
          <app-search></app-search>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, mapState } from 'vuex'
import { breakpoints } from '../mixins/breakpoints'
import User from './header/User.vue'
import Search from './header/Search.vue'

export default {
  mixins: [breakpoints],
  props: {
    languageHasChanged: false,
    menu: Boolean,
    ocMenu: Function,
  },
  components: {
    appUser: User,
    appSearch: Search,

  },
  data() {
    return {
      desktop: this.isDesktop(),
      translations: [],
      navKey: 0,

    }
  },
  async created() {
    await this.getMenuTranslations()
  },
  computed: {
    ...mapState({
      menuTranslations: (state) => state.translation.menuTranslations,
    }),
    ...mapGetters({
      applicationMenu: 'menu/applicationMenu',
      prefCulture: 'user/userPreferredCulture',
      userMenuKey: 'user/userMenuKey',
    }),
    displayMenu() {
      if (this.isMobile()) {
        return this.menu ? 'block' : 'none'
      }
      return 'block'
    },
  },
  methods: {
    ...mapActions({
      retrieveMenu: 'menu/setMenu',
      translateMenu: 'translation/fetchMenuTranslations',
    }),
    getMenuItemText(guid, defaultText) {
      const translatedText = this.menuTranslations[guid]
      return translatedText ? translatedText : defaultText
    },
    async getMenuTranslations(hasLanguageChanged) {
      if (!window.FreshworksWidget || hasLanguageChanged) this.$emit('langChange', true)
      await Promise.all([await this.retrieveMenu(this.userMenuKey), await this.translateMenu(this.prefCulture)])
    },
    toggleMenu(index, item) {
      if (this.isMobile()) {
        this.toggleMenuMobile(index, item)
      } else {
        this.toggleMenuDesktop(index, item)
      }
    },
    toggleMenuDesktop(index, item) {
      item.toggle = !item.toggle

      if (item.ChildItemList.length == 1) {
        item.ChildItemList[0].toggle = true
      }
    },
    toggleMenuMobile(index, item) {
      this.applicationMenu[index].toggle = !this.applicationMenu[index].toggle

      if (this.applicationMenu[index].ChildItemList.length == 1) {
        this.applicationMenu[index].ChildItemList[0].toggle = true
      }
    },
    toggleSubmenu(index, subIndex) {
      this.applicationMenu[index].ChildItemList[subIndex].toggle =
        !this.applicationMenu[index].ChildItemList[subIndex].toggle
    },
  },
  watch: {
    languageHasChanged: function (newVal) {
      if (newVal) this.getMenuTranslations(true)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/components/component-menu.scss';
</style>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
.user_show {
  display: none;
  @include breakpoint(sm) {
    display: inline;
  }
}
</style>
